import Cookies from 'js-cookie'; // 使用cookie存储语言
import intl from 'react-intl-universal'; // 三方库初始化语言api
// import en from './en-US'; // 英
import zh from './zh-CN'; // 简中
import { REGION } from './region';
// import hk from './zh-HK'; // 繁
import mitt from '../../utils/mitt'; // 中央事件总线

// 写入语言集合
const locales = {
  zh: {
    ...zh,
    REGION,
  },
  /* en, hk */
};

// const systemLangs = ['zh']

// 获取当前的语言
function getCurrentLocale() {
  const lang = intl.determineLocale({ cookieLocaleKey: 'lang' }); // 获取cookie携带的语言
  return lang.split('-')[0];
}

// 初始化语言
function initLanguage() {
  let lang = getCurrentLocale(); // 返回当前的语言
  // if (!systemLangs.includes(lang)) {
  //   lang = 'hk';
  // }
  Cookies.set('lang', lang); // 存储
  intl.init({ currentLocale: lang, locales }); // 初始化,返回一个promise
  mitt.emit("switchLanguage"); // 发布修改字典库方法
}

// 语言切换
function switchLanguage(language = '') {
  if (language && Object.prototype.hasOwnProperty.call(locales, language)) {
    // 切换的语言存在且语言存储在locales语言集中,进入执行
    intl.options.currentLocale = language; // 切换
    Cookies.set('lang', language); // 存储
  } else {
    const lang = intl.determineLocale({ cookieLocaleKey: 'lang' });
    // 默认情况时候切换
    intl.options.currentLocale = lang === 'zh' ? 'hk' : 'zh';
    // 存储当前设置的语言
    Cookies.set('lang', intl.options.currentLocale);
  }
}

export { initLanguage, switchLanguage, getCurrentLocale };
